'use client'

import * as RUIDialog from '@radix-ui/react-dialog'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

// import Pixie from 'asset/icon/tab/pixie_s_big.svg'
import PlayseeLogo from 'asset/icon/logo/playsee_logo_new.svg'
import PlayFill from 'asset/icon/tab/play_f_big.svg'
import Play from 'asset/icon/tab/play_s_big.svg'
import SearchFill from 'asset/icon/tab/search_f_big.svg'
import Search from 'asset/icon/tab/search_s_big.svg'
import CommunityFill from 'asset/icon/tab/us_f_big.svg'
import Community from 'asset/icon/tab/us_s_big.svg'
import Link from 'component/block/link'
import { Button } from 'component/ui/button'
import { Dialog, DialogTrigger } from 'component/ui/dialog'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from 'component/ui/hover-card'
import { useDeviceStore } from 'store/server-context/device'
import cn from 'util/cn'

const ListItem = (props: React.ComponentPropsWithoutRef<'li'>) => (
  <li
    {...props}
    className={cn(
      'w-[204px] px-[24px] py-[16px] min768:w-[96px] min768:p-0',
      'duration-100 group-data-[state=open]:animate-in group-data-[state=closed]:animate-out group-data-[state=closed]:slide-out-to-bottom-[--percent] group-data-[state=open]:slide-in-from-bottom-[--percent] ',
      props.className
    )}
  />
)

const ListItem2 = (props: React.ComponentPropsWithoutRef<'li'>) => (
  <li {...props} className={cn('', props.className)} />
)

const navLinks: {
  href: string
  title: Parameters<ReturnType<typeof useTranslations>>[0]
  icon: React.ReactNode
  hoverIcon: React.ReactNode
}[] = [
  {
    href: '/community',
    title: 'common.community',
    icon: <Community className='tab-icon h-[32px] w-[32px] flex-shrink-0' />,
    hoverIcon: (
      <CommunityFill className='tab-hover-icon !ml-0 hidden h-[32px] w-[32px] flex-shrink-0' />
    )
  },
  {
    href: '/spot',
    title: 'common.video',
    icon: <Play className='tab-icon h-[32px] w-[32px] flex-shrink-0' />,
    hoverIcon: (
      <PlayFill className='tab-hover-icon !ml-0 hidden h-[32px] w-[32px] flex-shrink-0' />
    )
  },

  // {
  //   href: '/pixie',
  //   title: 'Pixie',
  //   icon: <Pixie className='h-[32px] w-[32px] flex-shrink-0' />
  // },
  {
    href: '/search',
    title: 'common.search',
    icon: <Search className='tab-icon h-[32px] w-[32px] flex-shrink-0' />,
    hoverIcon: (
      <SearchFill className='tab-hover-icon !ml-0 hidden h-[32px] w-[32px] flex-shrink-0' />
    )
  }
]

const NavButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const device = useDeviceStore(state => state.device)
  const t = useTranslations()

  const hamburgerMenu = (
    <Button
      $variant='text-white'
      $icon={true}
      $size='sm'
      className={cn(
        'hamburger hamburger--squeeze absolute right-[12px] top-[12px] min768:right-[64px] min768:top-[48px]',
        isOpen && 'is-active'
      )}
      // TODO: translation
      title='Menu'
    >
      <div className='hamburger-box'>
        <div className='hamburger-inner' />
      </div>
    </Button>
  )

  if (device === 'desktop') {
    return (
      <HoverCard openDelay={0}>
        <HoverCardTrigger asChild={true}>{hamburgerMenu}</HoverCardTrigger>
        <HoverCardContent
          className='isolate w-[240px] overflow-hidden p-0'
          align='end'
          $asChild
        >
          <nav>
            <ul className='flex flex-col text-label-l1'>
              {navLinks.map(({ href, title, icon, hoverIcon }, index) => (
                <li key={index}>
                  <Link
                    href={href}
                    title={t(title)}
                    className='flex items-center space-x-[16px] px-[24px] py-[16px] hover:bg-background_elevated-2nd [&:hover>.tab-hover-icon]:block [&:hover>.tab-icon]:hidden'
                  >
                    {icon}
                    {hoverIcon}
                    <span>{t(title)}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </HoverCardContent>
      </HoverCard>
    )
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} modal={true}>
      <DialogTrigger asChild={true}>{hamburgerMenu}</DialogTrigger>
      <RUIDialog.Portal>
        <RUIDialog.Overlay className='fixed bottom-0 left-0 right-0 top-0 z-0 bg-overlay-light backdrop-blur-[50px] duration-100 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 min768:hidden' />
        <RUIDialog.Content asChild>
          <nav
            className={cn(
              'group fixed left-0 right-0 top-[48px] h-fit min768:hidden',
              'duration-100 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
              'flex justify-center pt-[24px] text-label_still-l1 outline-none'
            )}
          >
            <ul className='flex flex-col text-2xl min768:flex-row'>
              {navLinks.map(({ href, title, icon }, index) => (
                <ListItem
                  key={index}
                  style={
                    { '--percent': `${index * -100}%` } as React.CSSProperties
                  }
                >
                  <Link
                    href={href}
                    title={title}
                    className='flex items-center space-x-[16px] min768:flex-col min768:justify-center min768:space-x-0 min768:space-y-[6px]'
                  >
                    {icon}
                    <span>{t(title)}</span>
                  </Link>
                </ListItem>
              ))}
            </ul>
          </nav>
        </RUIDialog.Content>
      </RUIDialog.Portal>
    </Dialog>
  )
}

const HomeHeader = () => {
  return (
    <header className='fixed top-0 z-header w-full text-label_still-l1'>
      <div className='pointer-events-none absolute top-0 h-[128px] w-full bg-gradient-home-header' />
      <div className='relative flex h-[48px] items-center min768:h-[96px]'>
        <Link
          href='/'
          title='Playsee'
          className='absolute left-[16px] top-[12px] mx-auto min768:left-[64px] min768:top-[48px]'
          onClick={() => {
            window.location.reload()
          }}
        >
          <PlayseeLogo className='h-[24px] w-[80px] min768:h-[36px] min768:w-[118px]' />
        </Link>

        <NavButton />
      </div>
    </header>
  )
}

export default HomeHeader
