'use client'

import Link from 'component/block/link'
import { Button } from 'component/ui/button'
import { Image } from 'component/ui/image'
import { APP_DOWNLOAD_LINKS, CDN_URL, REDIRECT_APP_LINK } from 'constant'
import { useDeviceStore } from 'store/server-context/device'

const DownloadAppButton = ({ children }: { children: string }) => {
  const device = useDeviceStore(state => state.device)

  return device !== 'desktop' ? (
    <Button
      $variant='text-still'
      $size='md'
      $radius='md'
      className='absolute bottom-[40px] w-fit bg-background-1st'
      title={children}
      asChild={true}
    >
      <Link href={REDIRECT_APP_LINK} target='_blank'>
        {children}
      </Link>
    </Button>
  ) : (
    <div className='absolute bottom-[40px] flex w-fit space-x-[8px] min768:bottom-[36px] min768:right-[72px]'>
      <Link
        href={APP_DOWNLOAD_LINKS.android}
        title='Download Playsee for Android'
        target='_blank'
      >
        <Image
          src={`${CDN_URL}/common/images/google_play_gray.svg`}
          width={128}
          height={37}
          className='pointer-events-none h-[37px] w-[128px]' // pointer-events-none to prevent click event on the image
          alt='Google Play'
        />
      </Link>
      <Link
        href={APP_DOWNLOAD_LINKS.ios}
        title='Download Playsee for iOS'
        target='_blank'
      >
        <Image
          src={`${CDN_URL}/common/images/app_store_gray.svg`}
          width={128}
          height={37}
          className='pointer-events-none h-[37px] w-[128px]' // pointer-events-none to prevent click event on the image
          alt='App Store'
        />
      </Link>
    </div>
  )
}

export default DownloadAppButton
