'use client'

import * as HoverCardPrimitive from '@radix-ui/react-hover-card'

import { twc, type TwcComponentWithAsChild } from 'util/cn'

const HoverCard = HoverCardPrimitive.Root

const HoverCardTrigger = HoverCardPrimitive.Trigger

type HoverCardContentProps = TwcComponentWithAsChild<
  typeof HoverCardPrimitive.Content
>
const HoverCardContent = twc(
  HoverCardPrimitive.Content
).attrs<HoverCardContentProps>(props => ({
  asChild: props.$asChild
}))`z-popover rounded-[12px] bg-background-1st shadow-floating-dropdown outline-none
data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2
data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95
data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95`
HoverCardContent.displayName = `twc(${HoverCardPrimitive.Content.displayName}))`

export { HoverCard, HoverCardContent, HoverCardTrigger }
