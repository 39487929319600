import * as React from 'react'

function useLazyRef<T>(init: () => T): React.MutableRefObject<T> {
  const value = React.useRef<T | undefined>()
  if (!value.current) {
    value.current = init()
  }

  return value as React.MutableRefObject<T>
}

export default useLazyRef
