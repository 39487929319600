'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as React from 'react'

import IconArrowLeft from 'asset/icon/arrow_left.svg'
import IconClose from 'asset/icon/close.svg'
import { Button } from 'component/ui/button'
import { twc, type TwcComponentWithAsChild } from 'util/cn'

const Dialog = DialogPrimitive.Root
const DialogPortal = DialogPrimitive.Portal
const DialogTrigger = DialogPrimitive.Trigger
const DialogTitle = DialogPrimitive.Title
const DialogDescription = DialogPrimitive.Description
const DialogClose = DialogPrimitive.Close

const DialogCloseIcon = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentProps<typeof Button>
>((props, ref) => (
  <DialogPrimitive.Close
    asChild
    className='absolute right-[24px] top-[24px] z-[1]'
  >
    <Button ref={ref} $icon $size='sm' $variant='text-still' {...props}>
      <IconClose />
    </Button>
  </DialogPrimitive.Close>
))
DialogCloseIcon.displayName = 'DialogCloseIcon'

const DialogBackIcon = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentProps<typeof Button>
>((props, ref) => (
  <DialogPrimitive.Close
    asChild
    className='absolute left-[24px] top-[24px] z-[1]'
  >
    <Button ref={ref} $icon $size='sm' $variant='text-still' {...props}>
      <IconArrowLeft />
    </Button>
  </DialogPrimitive.Close>
))
DialogBackIcon.displayName = 'DialogBackIcon'

type DialogOverlayProps = TwcComponentWithAsChild<
  typeof DialogPrimitive.Overlay
>
const DialogOverlay = twc(DialogPrimitive.Overlay).attrs<DialogOverlayProps>(
  props => ({
    asChild: props.$asChild
  })
)`fixed bottom-0 left-0 right-0 top-0 z-modal-1 bg-overlay-light playsee-mb:bottom-[--cookie-hint-height]
data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0`
DialogOverlay.displayName = `twc(${DialogPrimitive.Overlay.displayName}))`

type DialogContentProps = TwcComponentWithAsChild<
  typeof DialogPrimitive.Content
>
const DialogContent = twc(DialogPrimitive.Content).attrs<DialogContentProps>(
  props => ({
    asChild: props.$asChild
  })
)`dialog-content fixed z-modal bg-background-1st p-[24px]`
DialogContent.displayName = `twc(${DialogPrimitive.Content.displayName}))`

export {
  Dialog,
  DialogBackIcon,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger
}
