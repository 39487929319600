import { CDN_URL } from 'constant'

const Logo = () => {
  return (
    <div className='relative isolate pt-[132px] min768:pt-0'>
      <img
        src={`${CDN_URL}/platform/images/playsee_logo_text_with_stroke.png`}
        width='732'
        height='226'
        alt='Playsee'
        className='hidden h-auto w-auto animate-fade-in [animation-delay:0.2s] [animation-duration:3s] [animation-fill-mode:both] min768:block'
      />
      <img
        src={`${CDN_URL}/platform/images/playsee_logo_text_with_stroke_mobile.png`}
        width='375'
        height='113'
        alt='Playsee'
        className='h-auto w-[375px] animate-fade-in [animation-delay:0.2s] [animation-duration:3s] [animation-fill-mode:both] min768:hidden'
      />
    </div>
  )
}

export default Logo
